const onLoad = () => {
    try {
        const APP_PREFIX = 'bbb_';
        const COOKIE_NAME = APP_PREFIX + 'hideAnnouncement';
        const Announcement = $('#Announcement');

        /* ---------------------------------------------------------------------------------------------- */
        /* Handle Announcement                                                                            */
        /* ---------------------------------------------------------------------------------------------- */
        Announcement.find('[aria-label="close"]').on('click', function (e) {
            e.preventDefault();
            $(this).parents('[aria-label="alert"]').first().remove();

            // When clicking on the close button, create a 1 year cookie
            setCookie(COOKIE_NAME, true, 365);
        })

        // cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

        // Show the alert if we cant find the COOKIE_NAME cookie
        if (!getCookie(COOKIE_NAME)) {
            Announcement.removeClass(App().realClass("hidden"));
        }

        // Cookie functions from w3schools
        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
    } catch (error) {
        throw 'Error in Announcement Handler';
    }
};
window.addEventListener("app:mounted", onLoad, { once: true });