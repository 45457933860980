// Vendors
import { tns } from "tiny-slider/src/tiny-slider.js";

// App plugins
import Slider from '../plugins/Slider/index';


/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Tiny Slider          *
 *     02.  Swiper slider        *
 *     03.  Countdown Js         * (For Comingsoon pages)
 *     04.  Maintenance js       * (For Maintenance page)
 *     05.  Data Counter         *
 *     07.  Gallery filter js    * (For Portfolio pages)
 *     08.  Lity lightbox        * (For Portfolio pages)
 *     09.  CK Editor            * (For Compose mail)
 *     11.  Typed Text animation * 
 *     12.  Validation Form      * 
 *     14.  Cookies Policy       *
 *     15.  Back Button          *
 *     16.  Particles            *
 *     17.  Lighthouse           * (Fix some warnings)
 *     18.  Components           *
 *          1. Navtabs           *
 *          2. Modal             *
 *          3. Carousel          *
 *          4. Accordions        *
 ================================*/

// plugins
import './plugins/datepicker';
// import './plugins/gallery-filter';

//=========================================//
/*/*            Fade Animation             */
//=========================================//
import './plugins/aos';

//=========================================//
/*            01) Tiny slider              */
//=========================================//
document.addEventListener('DOMContentLoaded', function () {
    Slider(tns);
})

//=========================================//
/*/*   Typed Text animation (animation)    */
//=========================================//
// import './plugins/typed';

//=========================================//
/*            02) Swiper slider            */
//=========================================//
// import './plugins/swiper-slider';

//=========================================//
/*            03) Countdown                */
//=========================================//
// import './plugins/countdown';


//=========================================//
/*/*            04) Maintenance js         */
//=========================================//
// import './plugins/maintenance';

//=========================================//
/*/*            05) Data Counter           */
//=========================================//
// import './plugins/counter';

//=========================================//
/*/*            07) Gallery filter js      */
//=========================================//
// import './plugins/gallery-filter';


//=========================================//
/*/*            08) Lity lightbox         */
//=========================================//
import './plugins/lity';

//=========================================//
/*/*            09) CK Editor              */
//=========================================//
// import "./plugins/classic-editor";

//=========================================//
/*/*      14) Cookies Policy               */
//=========================================//
import './plugins/cookie-handler';

//=========================================//
/*/*            15) Back Button            */
//=========================================//
import './custom/back-btn';

//=========================================//
/*            16) Particles                */
//=========================================//
// import './plugins/particles';

//=========================================//
/*            11) Choice js                */
//=========================================//
// import './plugins/choices';

//=========================================//
/*            18) Components               */
//=========================================//

//============= 01) Navtabs ===============//
try {
    const Default = {
        defaultTabId: null,
        activeClasses: App().realClass('text-white bg-base-primary'),
        inactiveClasses: App().realClass('hover:text-base-primary dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800'),
        onShow: () => { }
    }

    class Tabs {
        constructor(items = [], options = {}) {
            this._items = items
            this._activeTab = options ? this.getTab(options.defaultTabId) : null
            this._options = { ...Default, ...options }
            this._init()
        }

        _init() {
            if (this._items.length) {
                // set the first tab as active if not set by explicitly
                if (!this._activeTab) {
                    this._setActiveTab(this._items[0])
                }

                // force show the first default tab
                this.show(this._activeTab.id, true)

                // show tab content based on click
                this._items.map(tab => {
                    tab.triggerEl.addEventListener('click', () => {
                        this.show(tab.id)
                    })
                })
            }
        }

        getActiveTab() {
            return this._activeTab
        }

        _setActiveTab(tab) {
            this._activeTab = tab
        }

        getTab(id) {
            return this._items.filter(t => t.id === id)[0]
        }

        show(id, forceShow = false) {
            const tab = this.getTab(id)

            // don't do anything if already active
            if (tab === this._activeTab && !forceShow) {
                return
            }

            // hide other tabs
            this._items.map(t => {
                if (t !== tab) {
                    t.triggerEl.classList.remove(...this._options.activeClasses.split(" "));
                    t.triggerEl.classList.add(...this._options.inactiveClasses.split(" "));
                    t.targetEl.classList.add(App().realClass('hidden'))
                    t.triggerEl.setAttribute('aria-selected', false)
                }
            })

            // show active tab
            tab.triggerEl.classList.add(...this._options.activeClasses.split(" "));
            tab.triggerEl.classList.remove(...this._options.inactiveClasses.split(" "));
            tab.triggerEl.setAttribute('aria-selected', true)
            tab.targetEl.classList.remove(App().realClass('hidden'))

            this._setActiveTab(tab)

            // callback function
            this._options.onShow(this, tab)
        }

    }

    window.Tabs = Tabs;

    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('[data-tabs-toggle]').forEach(triggerEl => {

            const tabElements = []
            let defaultTabId = null
            triggerEl.querySelectorAll('[role="tab"]').forEach(el => {
                const isActive = el.getAttribute('aria-selected') === 'true'
                const tab = {
                    id: el.getAttribute('data-tabs-target'),
                    triggerEl: el,
                    targetEl: document.querySelector(el.getAttribute('data-tabs-target'))
                }
                tabElements.push(tab)

                if (isActive) {
                    defaultTabId = tab.id
                }
            })
            new Tabs(tabElements, {
                defaultTabId: defaultTabId
            })
        })
    })
} catch (error) {

}

//********* 2) Modals ********//
try {
    const Default = {
        placement: 'center',
        backdropClasses: App().realClass('bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40'),
        onHide: () => { },
        onShow: () => { },
        onToggle: () => { }
    }
    class Modal {
        constructor(targetEl = null, options = {}) {
            this._targetEl = targetEl
            this._options = { ...Default, ...options }
            this._isHidden = true
            this._init()
        }

        _init() {
            this._getPlacementClasses().map(c => {
                this._targetEl.classList.add(App().realClass(c))
            })
        }

        _createBackdrop() {
            if (this._isHidden) {
                const backdropEl = document.createElement('div');
                backdropEl.setAttribute('modal-backdrop', '');
                backdropEl.classList.add(...this._options.backdropClasses.split(" "));
                document.querySelector('body').append(backdropEl);
            }
        }

        _destroyBackdropEl() {
            if (!this._isHidden) {
                document.querySelector('[modal-backdrop]').remove();
            }
        }

        _getPlacementClasses() {
            switch (this._options.placement) {

                // top
                case 'top-left':
                    return App().realClass('justify-start items-start').trim().split(" ");
                case 'top-center':
                    return App().realClass('justify-center items-start').trim().split(" ");
                case 'top-right':
                    return App().realClass('justify-end items-start').trim().split(" ");

                // center
                case 'center-left':
                    return App().realClass('justify-start items-center').trim().split(" ");
                case 'center':
                    return App().realClass('justify-center items-center').trim().split(" ");
                case 'center-right':
                    return App().realClass('justify-end items-center').trim().split(" ");

                // bottom
                case 'bottom-left':
                    return App().realClass('justify-start items-end').trim().split(" ");
                case 'bottom-center':
                    return App().realClass('justify-center items-end').trim().split(" ");
                case 'bottom-right':
                    return App().realClass('justify-end items-end').trim().split(" ");

                default:
                    return App().realClass('justify-center items-center').trim().split(" ");
            }
        }

        toggle() {
            if (this._isHidden) {
                this.show()
            } else {
                this.hide()
            }

            // callback function
            this._options.onToggle(this)
        }

        show() {
            this._targetEl.classList.add(App().realClass('flex'))
            this._targetEl.classList.remove(App().realClass('hidden'))
            this._targetEl.setAttribute('aria-modal', 'true')
            this._targetEl.setAttribute('role', 'dialog')
            this._targetEl.removeAttribute('aria-hidden')
            this._createBackdrop()
            this._isHidden = false

            // callback function
            this._options.onShow(this)
        }

        hide() {
            this._targetEl.classList.add(App().realClass('hidden'))
            this._targetEl.classList.remove(App().realClass('flex'))
            this._targetEl.setAttribute('aria-hidden', 'true')
            this._targetEl.removeAttribute('aria-modal')
            this._targetEl.removeAttribute('role')
            this._destroyBackdropEl()
            this._isHidden = true

            // callback function
            this._options.onHide(this)
        }

    }

    window.Modal = Modal;

    const getModalInstance = (id, instances) => {
        if (instances.some(modalInstance => modalInstance.id === id)) {
            return instances.find(modalInstance => modalInstance.id === id)
        }
        return false
    }

    document.addEventListener('DOMContentLoaded', () => {
        let modalInstances = []
        document.querySelectorAll('[data-modal-toggle]').forEach(el => {
            const modalId = el.getAttribute('data-modal-toggle');
            const modalEl = document.getElementById(modalId);
            const placement = modalEl.getAttribute('data-modal-placement')

            if (modalEl) {
                if (!modalEl.hasAttribute('aria-hidden') && !modalEl.hasAttribute('aria-modal')) {
                    modalEl.setAttribute('aria-hidden', 'true');
                }
            }

            let modal = null
            if (getModalInstance(modalId, modalInstances)) {
                modal = getModalInstance(modalId, modalInstances)
                modal = modal.object
            } else {
                modal = new Modal(modalEl, {
                    placement: placement ? placement : Default.placement
                })
                modalInstances.push({
                    id: modalId,
                    object: modal
                })
            }

            el.addEventListener('click', () => {
                modal.toggle()
            })
        })
    })
} catch (error) {

}

//******** 3) Carousel********//
try {
    const Default = {
        defaultPosition: 0,
        indicators: {
            items: [],
            activeClasses: App().realClass('bg-white dark:bg-gray-800'),
            inactiveClasses: App().realClass('bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800')
        },
        interval: 6000,
        onNext: () => { },
        onPrev: () => { },
        onChange: () => { }
    }

    class Carousel {
        constructor(items = [], options = {}) {
            this._items = items
            this._options = { ...Default, ...options, indicators: { ...Default.indicators, ...options.indicators } }
            this._activeItem = this.getItem(this._options.defaultPosition)
            this._indicators = this._options.indicators.items
            this._interval = null
            this._init()
            this.cycle()

        }

        /**
         * Initialise carousel and items based on active one
         */
        _init() {
            this._items.map(item => {
                App().realClass('absolute inset-0 transition-all transform').trim().split(" ").forEach(cl => {
                    item.el.classList.add(cl)
                })
            })

            // if no active item is set then first position is default
            if (this._getActiveItem()) {
                this.slideTo(this._getActiveItem().position)
            } else {
                this.slideTo(0)
            }

            this._indicators.map((indicator, position) => {
                indicator.el.addEventListener('click', () => {
                    this.slideTo(position)
                })
            })
        }

        getItem(position) {
            return this._items[position]
        }

        /**
         * Slide to the element based on id
         * @param {*} position 
         */
        slideTo(position) {
            const nextItem = this._items[position]
            const rotationItems = {
                'left': nextItem.position === 0 ? this._items[this._items.length - 1] : this._items[nextItem.position - 1],
                'middle': nextItem,
                'right': nextItem.position === this._items.length - 1 ? this._items[0] : this._items[nextItem.position + 1]
            }
            this._rotate(rotationItems)
            this._setActiveItem(nextItem.position)
            if (this._interval) {
                this.pause()
                this.cycle()
            }

            this._options.onChange(this)
        }

        /**
         * Based on the currently active item it will go to the next position
         */
        next() {
            const activeItem = this._getActiveItem()
            let nextItem = null

            // check if last item
            if (activeItem.position === this._items.length - 1) {
                nextItem = this._items[0]
            } else {
                nextItem = this._items[activeItem.position + 1]
            }

            this.slideTo(nextItem.position)

            // callback function
            this._options.onNext(this)
        }

        /**
         * Based on the currently active item it will go to the previous position
         */
        prev() {
            const activeItem = this._getActiveItem()
            let prevItem = null

            // check if first item
            if (activeItem.position === 0) {
                prevItem = this._items[this._items.length - 1]
            } else {
                prevItem = this._items[activeItem.position - 1]
            }

            this.slideTo(prevItem.position)

            // callback function
            this._options.onPrev(this)
        }

        /**
         * This method applies the transform classes based on the left, middle, and right rotation carousel items
         * @param {*} rotationItems 
         */
        _rotate(rotationItems) {
            // reset
            this._items.map(item => {
                item.el.classList.add(App().realClass('hidden'))
            })

            // left item (previously active)
            App().realClass('-translate-x-full translate-x-full translate-x-0 hidden z-20').trim().split(" ").forEach(cl => {
                rotationItems.left.el.classList.remove(cl)
            })
            App().realClass('-translate-x-full z-10').trim().split(" ").forEach(cl => {
                rotationItems.left.el.classList.add(cl)
            })

            // currently active item
            App().realClass('-translate-x-full translate-x-full translate-x-0 hidden z-10').trim().split(" ").forEach(cl => {
                rotationItems.middle.el.classList.remove(cl)
            })
            App().realClass('translate-x-0 z-20').trim().split(" ").forEach(cl => {
                rotationItems.middle.el.classList.add(cl)
            })

            // right item (upcoming active)
            App().realClass('-translate-x-full translate-x-full translate-x-0 hidden z-20').trim().split(" ").forEach(cl => {
                rotationItems.right.el.classList.remove(cl)
            })
            App().realClass('translate-x-full z-10').trim().split(" ").forEach(cl => {
                rotationItems.right.el.classList.add(cl)
            })
        }

        /**
         * Set an interval to cycle through the carousel items
         */
        cycle() {
            this._interval = setInterval(() => {
                this.next();
            }, this._options.interval)
        }

        /**
         * Clears the cycling interval
         */
        pause() {
            clearInterval(this._interval);
        }

        /**
         * Get the currently active item
         */
        _getActiveItem() {
            return this._activeItem
        }

        /**
         * Set the currently active item and data attribute
         * @param {*} position 
         */
        _setActiveItem(position) {
            this._activeItem = this._items[position]

            // update the indicators if available
            if (this._indicators.length) {
                this._indicators.map(indicator => {
                    indicator.el.setAttribute('aria-current', 'false')
                    indicator.el.classList.remove(...this._options.indicators.activeClasses.split(" "))
                    indicator.el.classList.add(...this._options.indicators.inactiveClasses.split(" "))
                })
                this._indicators[position].el.classList.add(...this._options.indicators.activeClasses.split(" "))
                this._indicators[position].el.classList.remove(...this._options.indicators.inactiveClasses.split(" "))
                this._indicators[position].el.setAttribute('aria-current', 'true')
            }
        }

    }
 
    window.Carousel = Carousel;

    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('[data-carousel]').forEach(carouselEl => {
            const interval = carouselEl.getAttribute('data-carousel-interval')
            const slide = carouselEl.getAttribute('data-carousel') === 'slide' ? true : false

            const items = []
            let defaultPosition = 0
            if (carouselEl.querySelectorAll('[data-carousel-item]').length) {
                [...carouselEl.querySelectorAll('[data-carousel-item]')].map((carouselItemEl, position) => {
                    items.push({
                        position: position,
                        el: carouselItemEl
                    })

                    if (carouselItemEl.getAttribute('data-carousel-item') === 'active') {
                        defaultPosition = position
                    }
                })
            }

            const indicators = [];
            if (carouselEl.querySelectorAll('[data-carousel-slide-to]').length) {
                [...carouselEl.querySelectorAll('[data-carousel-slide-to]')].map((indicatorEl) => {
                    indicators.push({
                        position: indicatorEl.getAttribute('data-carousel-slide-to'),
                        el: indicatorEl
                    })
                })
            }

            const carousel = new Carousel(items, {
                defaultPosition: defaultPosition,
                indicators: {
                    items: indicators
                },
                interval: interval ? interval : Default.interval
            })

            if (slide) {
                carousel.cycle();
            }

            // check for controls
            const carouselNextEl = carouselEl.querySelector('[data-carousel-next]')
            const carouselPrevEl = carouselEl.querySelector('[data-carousel-prev]')

            if (carouselNextEl) {
                carouselNextEl.addEventListener('click', () => {
                    carousel.next()
                })
            }

            if (carouselPrevEl) {
                carouselPrevEl.addEventListener('click', () => {
                    carousel.prev()
                })
            }

        })
    })

} catch (error) {

}

//********4) Accordions********/
// import './plugins/accordion';

