import Lity from "lity/src/lity";

const onLoad = () => {
    try {
        $(document).on('click', '[data-lightbox]', Lity);
        // const Lity = new Lity();
    } catch (err) {
        //  throw 'error at line 431'
    }
};
window.addEventListener("app:mounted", onLoad, { once: true });