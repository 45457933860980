import App from './plugins/AppClasses';
window.App = App;

// Vendors
import $ from 'jquery';
import iziToast from 'izitoast/dist/js/iziToast.min.js';

// App plugins
import loader from './plugins/loader';
import errorsHandler from './plugins/error-handler';

/* ---------------------------------------------------------------------------------------------- */
/* Define Jquery & iziToast                                                                       */
/* ---------------------------------------------------------------------------------------------- */
window.$ = window.jQuery = $;
window.iziToast = iziToast;

/* Loader --------------------------------------------------------------------------------------- */
try {
    loader();
} catch (error) {
    throw 'Error in loader.js file'
}

/* Error Handler -------------------------------------------------------------------------------- */
errorsHandler();

//=========================================//
/*           Announcement Handler          */
//=========================================//
import './plugins/announcement-handler';

// plugins
import './plugins.js';

/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Loader               *
 *     02.  Toggle Menus         *
 *     03.  Active Menu          *
 *     04.  Clickable Menu       *
 *     05.  Back to top          *
 *     06.  Feather icon         *
 *     06.  DD Menu              *
 *     06.  Active Sidebar Menu  *
 *     07.  Newsletter           *
 *     08.  Announcement         *
 ================================*/
window.addEventListener('load', fn, false);

$.fn.hasAttr = function (name) {
    return typeof this.attr(name) !== undefined;
};

//  window.onload = function loader() {
function fn() {
    // Menus
    activateMenu();
    scrollToElement();

    // console.log(AppClasses);

    // var elements = document.querySelectorAll('*');
    // elements.forEach(function (el) {
    //     let classes = el.classList;
    //     if (classes.length > 0)
    //         classes.forEach(cl => {
    //             if (AppClasses[cl]) {
    //                 el.classList.remove(cl);
    //                 el.classList.add(AppClasses[cl]);

    //             }
    //         });
    // })

    // for (const key in AppClasses) {
    //     if (Object.hasOwnProperty.call(AppClasses, `${key}`)) {
    //         const value = AppClasses[`${key}`];
    //         let el = document.getElementsByClassName(key);
    //         for (let i = 0; i < el.length; i++) {
    //             const element = el[i];
    //             element.classList.remove(key);
    //             element.classList.add(value)
    //         }
    //     }
    // }

    // $('*').each(function (el) {
    //     el = $(this);
    //     let classes = $(this).attr('class');
    //     if (classes !== undefined) {
    //         classes = classes.split(/\s+/)
    //         $.each(classes, function (index, item) {
    //             if (AppClasses[item]) {
    //                 el.removeClass(item).addClass(AppClasses[item]);

    //             }

    //         });
    //     }
    // })

}

//Menu
/*********************/
/* Toggle Menu */
/*********************/
function toggleMenu() {
    $('#isToggle').toggleClass('open');
    var isOpen = $('#navigation')
    if (isOpen.css('display') == "block") {
        isOpen.css('display', "none");
    } else {
        isOpen.css('display', "block");
    }
};

$('#isToggle').on('click', toggleMenu);

/*********************/
/*    Menu Active    */
/*********************/
function getClosest(elem, selector) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) { }
                return i > -1;
            };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;

};

function activateMenu() {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems.length == 0) return;

    var matchingMenuItem = null;
    for (let i = 0; i < menuItems.length; i++) {
        if (menuItems[i].href === window.location.href) matchingMenuItem = menuItems[i];
    }

    if (matchingMenuItem === null) return;

    matchingMenuItem.classList.add('active');

    var immediateParent = getClosest(matchingMenuItem, 'li');

    if (immediateParent) immediateParent.classList.add('active');

    var parent = getClosest(immediateParent, '.child-menu-item');
    if (parent) {
        parent.classList.add('active');
    }

    var parent = getClosest(parent || immediateParent, '.parent-menu-item');

    if (parent) {
        parent.classList.add('active');

        var parentMenuitem = parent.querySelector('.menu-item');
        if (parentMenuitem) {
            parentMenuitem.classList.add('active');
        }

        var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
        if (parentOfParent) {
            parentOfParent.classList.add('active');
        }
    } else {
        var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
        if (parentOfParent) {
            parentOfParent.classList.add('active');
        }
    }

}
/*********************/
/*  Clickable manu   */
/*********************/
if (document.getElementById("navigation")) {
    var elements = document.getElementById("navigation").getElementsByTagName("a");
    for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].onclick = function (elem) {
            if (elem.target.getAttribute("href") === "javascript:void(0)") {
                var submenu = elem.target.nextElementSibling.nextElementSibling;
                submenu.classList.toggle('open');
            }
        }
    }
}
/*********************/
/*   Menu Sticky     */
/*********************/
function windowScroll() {
    const navbar = document.getElementById("topnav");
    if (navbar != null) {
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add("nav-sticky");
        } else {
            navbar.classList.remove("nav-sticky");
        }
    }
}

window.addEventListener('DOMContentLoaded', function () {
    windowScroll()
})
window.addEventListener('scroll', (ev) => {
    ev.preventDefault();
    windowScroll();
})
/*********************/
/*    Back To TOp    */
/*********************/

window.onscroll = function () {
    scrollFunction();
};

function scrollFunction() {
    var mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
        if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            mybutton.classList.add(App().realClass("block"));
            mybutton.classList.remove(App().realClass("hidden"));
        } else {
            mybutton.classList.add(App().realClass("hidden"));
            mybutton.classList.remove(App().realClass("block"));
        }
    }
}

function topFunction(e) {
    e.preventDefault();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};

$('#back-to-top').on('click', topFunction);

/*********************/
/*  Active Sidebar   */
/*********************/
(function () {
    var current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);;
    if (current === "") return;
    var menuItems = document.querySelectorAll('.sidebar-nav a');
    for (var i = 0, len = menuItems.length; i < len; i++) {
        if (menuItems[i].getAttribute("href").indexOf(current) !== -1) {
            menuItems[i].parentElement.className += " active";
        }
    }
})();

/*********************/
/*   Feather Icons   */
/*********************/
if (window.feather) feather.replace();

/*********************/
/*    DD Menu        */
/*********************/
var ddmenu = document.getElementsByClassName("dd-menu");
for (var i = 0, len = ddmenu.length; i < len; i++) {
    ddmenu[i].addEventListener('click', function (elem) {
        elem.stopPropagation();
    })
}

/*********************/
/*     Small Menu    */
/*********************/
try {
    var spy = new Gumshoe('#navmenu-nav a');
} catch (err) {

}

/*********************/
/* Dark & Light Mode */
/*********************/
try {
    function changeTheme(e) {
        e.preventDefault()
        const htmlTag = document.getElementsByTagName("html")[0]
        htmlTag.classList.toggle(App().realClass('dark'))
        htmlTag.classList.toggle(App().realClass('light'))
    }

    const switcher = $('#theme-mode');
    switcher.on("click", changeTheme)

    const toggle = $('#dark-mode-switcher');

    toggle.on('change', changeTheme);
} catch (err) {
    console.log('error at:276');
}

function scrollToElement() {
    Array.from(document.getElementsByClassName('scroll-to-element')).forEach(element => {
        let id = element.getAttribute('href');
        if (id === null) return;
        if (!id.startsWith('#')) return;
        id = id.replace(/#/g, '');
        let el = document.getElementById(id);
        if (el) {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                el.scrollIntoView();
            })
        }
    });
}

//=========================================//
/*               Lighthouse                */
//=========================================//
/* ---------------------------------------------------------------------------------------------- */
/* Fix "Does Not Use Passive Listeners To Improve Scrolling Performance" (Lighthouse Report)      */
/* ---------------------------------------------------------------------------------------------- */
$.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
$.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
$.event.special.wheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("wheel", handle, { passive: true });
    }
};
$.event.special.mousewheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

/* Custom --------------------------------------------------------------------------------------- */
document.addEventListener("DOMContentLoaded", () => {
    window.dispatchEvent(new CustomEvent("app:mounted"));
});
