import Aos from "aos";

const onLoad = () => {
    try {
        Aos.init({
            duration: 1000
        });
    } catch (err) {
        throw 'Error in aos.js'
    }
};
window.addEventListener("app:mounted", onLoad, { once: true });