const onLoad = () => {
    try {
        if (window.datepicker) {
            const start = datepicker('.start', { id: 1 })
            const end = datepicker('.end', { id: 1 })
        }
    } catch (err) {
        // throw 'error in datepicker plugin'
    }
};

window.addEventListener("app:mounted", onLoad, { once: true });