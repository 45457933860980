const onLoad = () => {
    try {
        const APP_PREFIX = 'bbb_';
        const COOKIE_NAME = APP_PREFIX + 'acceptCookies';
        var cookieAlert = document.querySelector(".cookie-popup");
        var acceptCookies = document.querySelector(".cookie-consent__agree");

        if (!cookieAlert) {
            return;
        }

        cookieAlert.offsetHeight; // Force browser to trigger reflow (https://stackoverflow.com/a/39451131)

        // Show the alert if we cant find the COOKIE_NAME cookie
        if (!getCookie(COOKIE_NAME)) {
            cookieAlert.classList.remove(App().realClass("hidden"));
        }

        // When clicking on the agree button, create a 1 year
        // cookie to remember user's choice and close the banner
        acceptCookies.addEventListener("click", function () {
            setCookie(COOKIE_NAME, true, 365);
            cookieAlert.classList.add(App().realClass("hidden"));

            // dispatch the accept event
            window.dispatchEvent(new Event("cookieAlertAccept"))
        });

        // Cookie functions from w3schools
        function setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
    } catch (error) {
        throw error + ' at cookie handler';
    }

};
window.addEventListener("app:mounted", onLoad, { once: true });
